import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../layouts/index";
import Img from "gatsby-image";

export default () => (
  <StaticQuery
    query={graphql`
      query GalleryQuery {
        restaurants: allDatoCmsRestaurant {
          edges {
            node {
              id
              name
              descriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              image {
                url
                sizes(maxWidth: 300, imgixParams: { fm: "jpg" }) {
                  ...GatsbyDatoCmsSizes
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `}
    render={(data) => (
      <Layout site={data.site} title="Restaurants">
        <div className="catalogue">
          {data.restaurants.edges.map(({ node: restaurant }) => (
            <div key={restaurant.id} className="card snipcart-add-item">
              <div className="card-image">
                <Img
                  sizes={restaurant.image.sizes}
                  className="img-responsive"
                />
              </div>
              <div class="card-header">
                <div class="card-title h5 text-bold">{restaurant.name}</div>
              </div>
              <div
                class="card-body"
                dangerouslySetInnerHTML={{
                  __html: restaurant.descriptionNode.childMarkdownRemark.html,
                }}
              />
            </div>
          ))}
        </div>
      </Layout>
    )}
  />
);
